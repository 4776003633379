.woodBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://imagedelivery.net/O92oIvxodT0mXWz4vHbrUg/a3c5b7d7-93f4-4b5c-d5f2-f33f9657f300/publiclarge');
  background-size: auto;
  background-position-x: 100%;
  z-index: -1;
}
