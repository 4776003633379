.feedCard {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 80%,
    rgba(169, 169, 169, 0.3) 80%,
    rgb(169, 169, 169, 0.3) 100%
  );
}
@media (max-width: 767px) {
  .feedCard {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 65%,
      rgba(169, 169, 169, 0.3) 65%,
      rgb(169, 169, 169, 0.3) 100%
    );
  }
}

.feedLimitIndicator {
  background: linear-gradient(to bottom, #d6a444 0%, #835f18 100%);
}
.pageNumbers li.active {
  background-color: #a9a9a9;
  border-color: white;
  color: black;
}

.wrapper {
  overflow: hidden;

  display: block;

  width: 100%;
}

/* #coinMachineDiv{
    background-image: -webkit-image-set(('../../Images/DukFeeder-animation-small.webp')2x );
} */

/* .List {
    border: 1px solid #d9dddd;
  } */

.ListItemEven,
.ListItemOdd {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #363636;
  opacity: 0.5;
}

.ListItemEven {
  background-color: #464646;
  opacity: 0.5;
}

mark {
  color: black;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
