.duckIcon{
    height: 40px !important;
    width:40px !important;
}

body {
  background: linear-gradient(to bottom, #271D0F, #48371F);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

body {
    color: #E2E2E2;
}