@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  color: #e2e2e2;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes horizontal-bounce {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-horizontal-bounce {
  animation: horizontal-bounce 1s infinite;
}

.loader {
  width: 48px;
  height: 48px;
  display: block;
  margin: 20px auto;
  position: relative;
  background-color: transparent;
  border: 5px solid #a9a9a9;
  border-radius: 50%;
  box-sizing: border-box;
  animation: animate-loader 2s linear infinite;
}
.loader::after {
  content: '';
  box-sizing: border-box;
  width: 6px;
  height: 24px;
  background: #a9a9a9;
  transform: rotate(-45deg);
  position: absolute;
  bottom: -20px;
  left: 46px;
}

@keyframes animate-loader {
  0% {
    transform: translate(-10px, -10px);
  }
  25% {
    transform: translate(-10px, 10px);
  }
  50% {
    transform: translate(10px, 10px);
  }
  75% {
    transform: translate(10px, -10px);
  }
  100% {
    transform: translate(-10px, -10px);
  }
}

.hatchable-egg {
  /* animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite; */
  /* transform: translate3d(0, 0, 0); */
  animation: vibrate 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) 14 alternate;
  transform-origin: center bottom;
  backface-visibility: hidden;
  perspective: 1000px;
}

.hatchable-egg1 {
  animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
  /* transform: translate3d(0, 0, 0);
  /* animation: vibrate 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) 14 alternate;
  transform-origin: center bottom; */
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes vibrate {
  from {
    transform: rotate(-2deg);
  }
  to {
    transform: rotate(2deg);
  }
}

@media only screen and (max-device-width: 480px) {
  html {
    height: calc(100% + 60px);
    min-height: calc(100% + 60px);
  }
}
